import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./homeHero.module.scss"

interface HomeHeroProps {
  title: string
  text?: string
  cta?: CtaProps
}

const HomeHero = ({ title, text, cta }: HomeHeroProps) => (
  <section className={styles.homeHero}>
    <Container className={styles.container}>
      <StaticImage
        src="../images/hero-illustration.png"
        className={styles.picture}
        placeholder="blurred"
        objectFit="contain"
        alt=""
      />
      <h1 className={styles.title}>{parse(title, { trim: true })}</h1>
      {text && <Wysiwyg className={styles.text} contentHtml={text} />}
      <Cta {...cta} className={styles.buttonCta} />
    </Container>
  </section>
)

export default HomeHero

interface HomeHeroContainerProps {
  children?: React.ReactNode
}

export const HomeHeroContainer = ({ children }: HomeHeroContainerProps) => {
  return (
    <div className={styles.sectionContainer}>
      {children}
      <StaticImage
        src="../images/hero-background.png"
        className={styles.background}
        placeholder="blurred"
        objectPosition={"right top"}
        objectFit="contain"
        alt=""
      />
    </div>
  )
}
