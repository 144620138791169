import * as React from "react"
import { useRef } from "react"
import cx from "classnames"

import Container from "@components/elements/container"
import TextBox from "@components/elements/textBox"
import useIntersection from "@lib/useIntersection"

import styles from "./quoteBox.module.scss"

interface QuoteBoxProps {
  text?: string
  caption?: string
}

const QuoteBox = ({ text, caption }: QuoteBoxProps) => {
  const domRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersection(domRef, {
    threshold: 1,
    freezeOnceVisible: true,
  })
  const isVisible = !!entry?.isIntersecting

  return (
    <section
      className={cx(styles.quoteBox, {
        [styles.isVisible]: isVisible,
      })}
      ref={domRef}
    >
      <Container className={styles.container}>
        <TextBox text={text} caption={caption} />
      </Container>
    </section>
  )
}

export default QuoteBox
