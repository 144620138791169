import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import HomeHero, { HomeHeroContainer } from "@components/homeHero"
import Logos from "@components/logos"
import QuoteBox from "@components/quoteBox"
import DetailsList from "@components/detailsList"
import HexContent from "@components/hexContent"
import CtaSection from "@components/ctaSection"

const IndexPage = ({ data }: any) => {
  const {
    heroData,
    logosData,
    quoteBox1Data,
    detailsListData,
    quoteBox2Data,
    hexContentData,
    ctaSectionData,
  } = data.pageData

  return (
    <Layout>
      <HomeHeroContainer>
        <HomeHero {...heroData} />
        <Logos {...logosData} />
      </HomeHeroContainer>
      <QuoteBox {...quoteBox1Data} />
      <DetailsList {...detailsListData} bigSpace />
      <QuoteBox {...quoteBox2Data} />
      <HexContent {...hexContentData} />
      <CtaSection {...ctaSectionData} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    pageData: homepageJson {
      heroData: hero {
        title
        text
        cta {
          text
          url
        }
      }
      logosData: logos {
        title
        items {
          title
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      quoteBox1Data: quoteBox1 {
        text
        caption
      }
      quoteBox2Data: quoteBox2 {
        text
        caption
      }
      detailsListData: detailsList {
        title
        items {
          title
          text
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      hexContentData: hexContent {
        title
        text
        cta {
          text
          url
        }
      }
      ctaSectionData: ctaSection {
        title
        cta {
          text
          url
        }
      }
    }
  }
`
