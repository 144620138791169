import * as React from "react"
import cx from "classnames"

import Wysiwyg from "@components/elements/wysiwyg"

import styles from "./textBox.module.scss"

interface TextBoxProps {
  text?: string
  caption?: string
  className?: string
  isSmall?: boolean
}

const TextBox = ({ text, caption, className, isSmall }: TextBoxProps) => {
  return (
    <div
      className={cx(styles.textBox, className, {
        [styles.isSmall]: isSmall,
      })}
    >
      {text && <Wysiwyg className={styles.text} contentHtml={text} />}
      {caption && (
        <>
          <div className={styles.separator}>~</div>
          <Wysiwyg className={styles.caption} contentHtml={caption} />
        </>
      )}
    </div>
  )
}

export default TextBox
