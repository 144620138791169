import * as React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import cx from "classnames"

import Section from "@components/elements/section"
import styles from "./logos.module.scss"

interface LogosProps {
  title?: string
  items: Array<{
    title: string
    image: ImageDataLike
  }>
  columns?: number
}

const Logos = ({ title, items, columns = 3 }: LogosProps) => (
  <Section className={styles.logos} containerClassName={styles.container}>
    {title && <h2 className={styles.title}>{title}</h2>}

    <div className={cx(styles.list, styles[`columns${columns}`])}>
      {items?.map(item => {
        const image = getImage(item.image)

        return (
          <div key={item.title} className={styles.item}>
            <GatsbyImage
              className={styles.itemPicture}
              image={image}
              objectFit="contain"
              alt={item.title}
            />
          </div>
        )
      })}
    </div>
  </Section>
)

export default Logos
