import * as React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import cx from "classnames"

import Section from "@components/elements/section"
import LinesGap from "@components/elements/linesGap"
import ScrollTrigger from "@components/elements/scrollTrigger"

import styles from "./detailsList.module.scss"

interface DetailsListProps {
  title?: string
  items: Array<{
    title: string
    text?: string
    image: ImageDataLike
  }>
  bigSpace?: boolean
  variant?: "centered"
}

const DetailsList = ({ title, variant, bigSpace, items }: DetailsListProps) => (
  <Section
    bigSpace={bigSpace}
    className={cx(styles.detailsList, {
      [styles.isCentered]: variant === "centered",
    })}
  >
    {title && <LinesGap title={title} variant="mauve" bigTitle />}
    <div className={styles.list}>
      {items.map(item => {
        const image = getImage(item.image)

        return (
          <div key={item.title} className={styles.item}>
            <ScrollTrigger>
              <GatsbyImage
                className={styles.itemPicture}
                image={image}
                objectFit="contain"
                objectPosition={
                  variant === "centered" ? "center" : "left center"
                }
                alt=""
              />
            </ScrollTrigger>
            <h3 className={styles.itemTitle}>{item.title}</h3>
            {item.text && <div className={styles.itemText}>{item.text}</div>}
          </div>
        )
      })}
    </div>
  </Section>
)

export default DetailsList
