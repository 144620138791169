import * as React from "react"
import { useRef } from "react"
import cx from "classnames"

import Section from "@components/elements/section"
import LinesGap from "@components/elements/linesGap"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"
import useIntersection from "@lib/useIntersection"

import HexArrows from "@images/hex-arrows.inline.svg"

import styles from "./hexContent.module.scss"

interface HexContentProps {
  title: string
  text?: string
  cta?: CtaProps
}

const HexContent = ({ title, text, cta }: HexContentProps) => {
  const domRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersection(domRef, {
    threshold: 0.5,
    freezeOnceVisible: true,
  })
  const isVisible = !!entry?.isIntersecting

  return (
    <Section className={styles.hexContent}>
      {title && (
        <LinesGap variant="primary">
          <h2 className={styles.title}>{title}</h2>
        </LinesGap>
      )}
      <div className={styles.content} ref={domRef}>
        <HexArrows
          className={cx(styles.picture, {
            [styles.isVisible]: isVisible,
          })}
        />
        {text && <Wysiwyg className={styles.text} contentHtml={text} />}
        <Cta {...cta} className={styles.buttonCta} />
      </div>
    </Section>
  )
}

export default HexContent
